import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';
import { AvailableColors } from '../ThemeProvider';

const Heading = ({
  level,
  children,
  color,
  className,
  hidden,
}) => {
  return (
    <S.Heading
      as={`h${level}`}
      level={level}
      color={color}
      className={className}
      hidden={hidden}
    >
      {children}
    </S.Heading>
  )
};

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(AvailableColors),
  className: PropTypes.string,
  hidden: PropTypes.bool,
};

Heading.defaultProps = {
  level: 1,
  color: 'inherit',
  hidden: false,
};

export { S as HeadingStyles };
export default Heading;
