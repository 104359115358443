import React, { useMemo } from 'react'
import { HeroFragmentFragment, HeroImageFragment } from '../../generated/graphql-operations'
import { graphql } from 'gatsby'
import Image from 'widgets/Image'
import loadable, { LoadableComponent } from '@loadable/component'

const UIHeroCorner = loadable(() => import('ui/elements/Hero/Corner/index.jsx'))
const UIHeroPolygon = loadable(() => import('ui/elements/Hero/Polygon/index.jsx'))

export type LocalizedString = Partial<Record<"de" | "en" | "localized" | string, string>>
export type Hero = HeroFragmentFragment & {
  gatsbyImageData?: HeroImageFragment['gatsbyImageData']
  asset?: HeroImageFragment
  crop?: any
  hotspot?: any
}
type Data = {
  hero: Hero
}

const useHeroData = (data: Data) => {
  return useMemo(() => {
    const hero = data?.hero

    if (!hero) {
      return null
    }

    return {
      title: hero?.title?.localized,
      subtitle: hero?.subtitle?.localized,
      image: {
        asset: hero?.gatsbyImageData ? {
          gatsbyImageData: hero.gatsbyImageData
        } : hero?.image ?? hero?.asset,
        crop: hero?.crop,
        hotspot: hero?.hotspot,
      },
    }
  }, [data]);
}

export const HeroPolygon: React.FC<{
  data: Data,
} & React.ComponentProps<typeof UIHeroPolygon>> = ({ data, ...props }) => {
  return <RenderHero component={UIHeroPolygon} data={data} heroProps={props} />
};

export const HeroCorner: React.FC<{
  data: Data,
} & React.ComponentProps<typeof UIHeroCorner>> = ({ data, ...props }) => {
  return <RenderHero component={UIHeroCorner} data={data} heroProps={props} />
};

type BaseProps = {
  heading?: string
  subheading?: string
}
type RenderHeroProps<T extends BaseProps> = React.PropsWithChildren<{
  component: LoadableComponent<T> | React.FC<T>
  data: Data,
  heroProps: T
}>

function RenderHero<T extends BaseProps> ({
  component: Component,
  data,
  heroProps,
}: RenderHeroProps<T>): React.ReactElement | null {
  const heroData = useHeroData(data);

  const heroFromData = useMemo(() => {
    if (!heroData) {
      return
    }

    const image = heroData?.image
    const heading = heroData.title

    return {
      heading,
      subheading: heroData.subtitle,
      background: image?.asset && (
        <Image
          asset={image.asset}
          layout="fullWidth"
          style={{ objectFit: 'cover', objectPosition: 'center center' }}
          alt={heading ?? ""}
        />
      )
    };
  }, [heroData]);

  if (!heroFromData || !heroProps) {
    return null;
  }

  return (
    <Component
      {...heroFromData}
      {...heroProps}
    />
  );
}

export const query = graphql`
  fragment HeroFragment on SanityHero {
    title {
      en
      de
      localized
    }
    subtitle {
      en
      de
      localized
    }
    image {
      ...HeroImage
    }
  }

  fragment HeroImage on SanityImage {
    gatsbyImageData(
      layout: FULL_WIDTH
      fit: FILL
      height: 600
    )
  }
`
