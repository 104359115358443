import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import * as S from './styles';
import { graphql } from 'gatsby'

const Contact = ({
  contact,
}) => {
  const { t } = useTranslation('common');
  if (!contact) return null;

  const {
    firstName, lastName,
    title,
    phone,
    linkedin,
    email,
    image,
  } = contact;

  return (
    <S.Contact>
      <Container fluid={true} md={true} sm={true}>
        <Row align='center' justify='center'>
          <Col xs={6} sm={4} lg={2} component='aside'>
            {image.gatsbyImageData && <S.Img
              gatsbyImageData={image.gatsbyImageData}
              square
              style={{ objectFit: "contain" }}
              alt={t('contactTitle')}
            />}
          </Col>
          <Col xs={12} sm={8} lg={10}>
            <S.Header>
              <span className="h2">{t('contactTitle')}</span>
              <span className="h3">{`${firstName} ${lastName}`}</span>
              <span className="h6">{title}</span>
            </S.Header>
            <ul>
              {
                email && (
                  <li>
                    <span>Email</span>
                    <a href={`mailto: ${email}`}>{email}</a>
                  </li>
                )
              }
              {
                phone && (
                  <li>
                    <span>Phone</span>
                    <a href={`tel: ${phone}`}>{phone}</a>
                  </li>
                )
              }
              {
                linkedin && (
                  <li>
                    <a rel='noreferrer' target='_blank' href={linkedin}>
                      LinkedIn
                    </a>
                  </li>
                )
              }
            </ul>
          </Col>
        </Row>
      </Container>
    </S.Contact>
  )
};

export default Contact;

export const query = graphql`
  fragment ContactFragment on SanityContact {
    id
    firstName
    lastName
    title
    email
    linkedin
    phone
    image {
      gatsbyImageData(
        width: 165
        height: 165
        aspectRatio: 1
        fit: CROP
        layout: FIXED
      )
    }
  }
`;
