import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components';
import type { SanityAsset as _SanityAsset } from '@sanity/image-url/lib/types/types'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import { config } from 'utils/sanity'
import { GatsbyImage, IGatsbyImageData, Layout } from 'gatsby-plugin-image'
import { SanityRef } from 'gatsby-source-sanity/lib/types/sanity'

const SImage = styled(GatsbyImage)`
  max-width: 100%;
  height: auto;
  display: block;

  &[data-lqip] {
    width: 100%;
    object-fit: cover;
  }

  &[data-loading=true] {
    top: 0;
    left: 0;
    z-index: 10;
  }
`;

const SImageWrap = styled.div<{
  $square?: boolean
  $full?: boolean
}>`
  position: relative;
  display: block;
  max-width: 100%;
  
  ${props => props.$square && css`
    ${SImage} {
      aspect-ratio: 1/1;
    }
  `}
  
  ${props => props.$full && css`
    &, ${SImage} {
      height: 100%;
    }
  `}
`

export const Styles = {
  Image: SImage,
  ImageWrap: SImageWrap,
}

type SanityAsset = _SanityAsset & {
  metadata?: any
  gatsbyImageData?: IGatsbyImageData
}
export type ImageAsset = SanityAsset & Partial<SanityRef> | SanityRef & Partial<SanityAsset>
export type ImageFit = 'clip' | 'crop' | 'fill' | 'fillmax' | 'max' | 'scale' | 'min'

const Image: React.FC<{
  alt: string
  asset?: ImageAsset
  gatsbyImageData?: IGatsbyImageData
  square?: boolean
  width?: number
  height?: number
  style?: CSSProperties
  layout?: Layout
  fit?: ImageFit
  crop?: any
  hotspot?: any
  className?: string
  stretch?: boolean
}> = ({
  alt,
  asset,
  gatsbyImageData,
  square,
  width,
  height,
  style,
  fit,
  layout = "constrained",
  crop,
  hotspot,
  className,
  stretch,
}) => {
  const [dimensions, setDimensions] = useState<{ width: number, height: number }>()

  const handleRef = useCallback((wrapper: HTMLDivElement|null) => {
    if (!wrapper) {
      return
    }

    setDimensions({
      width: wrapper.scrollWidth,
      height: wrapper.scrollHeight,
    })
  }, [])

  const size = useMemo(() => {
    if (width || height) {
      return {
        width,
        height,
      }
    }

    if (!dimensions) {
      return undefined
    }

    return {
      width: dimensions?.width,
      height: dimensions.height,
    }
  }, [width, height, dimensions])

  const image = useMemo(() => {
    if (gatsbyImageData) {
      return gatsbyImageData
    }

    if (asset?.gatsbyImageData) {
      return asset.gatsbyImageData
    }

    if (!size || (!asset?._id && !asset?._ref)) {
      return undefined
    }

    const image = getGatsbyImageData({
      asset: asset as any,
      crop,
      hotspot,
    } as any, {
      fit,
      width: size.width,
      height: size.height,
      layout,
      placeholder: "dominantColor",
      aspectRatio: square ? 1 : undefined
    }, config)

    if (image && asset.metadata?.preview) {
      image.placeholder = {
        fallback: asset.metadata.preview
      }
    }

    return image
  }, [fit, layout, size, asset, crop, hotspot, square, dimensions, gatsbyImageData])

  if (!image && size) {
    return null;
  }

  return (
    <SImageWrap
      $square={square}
      $full={stretch || layout === "fullWidth"}
      className={className}
      ref={handleRef}
    >
      {image && <SImage
        image={image}
        alt={alt || 'sanity image'}
        objectFit={style?.objectFit}
        objectPosition={style?.objectPosition}
      />}
    </SImageWrap>
  );
};

export default Image;
