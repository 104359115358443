import styled from 'styled-components';
import { breakpoint } from 'ui/elements/ThemeProvider/utils';

import Image from 'widgets/Image';

export const Contact = styled.div`
  color: ${(props) => props.theme.palette.white};
  background: ${(props) => props.theme.palette.shade};
  padding: 40px 0;
  font-size: 1.4rem;
  text-align: center;
  
  ul {
    list-style: none;
    margin: 20px 0 0;
    padding: 0;
    
    a {
      color: inherit;
      
      &:hover {
        color: ${(props) => props.theme.palette.primary};
        text-decoration: none;
      }
    }
    
    span {
      padding-right: 5px;
      
      &:after {
        content: ':';
      }
    }
  }
  
  ${breakpoint.sm`
    text-align: left;
  `}
`;

export const Header = styled.header`
  .h2, .h3, .h6 {
    display: block;
  }

  .h2 {
    color: ${(props) => props.theme.palette.tertiaryBeta};
    line-height: 1;
    font-size: 2.3rem;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .h3 {
    font-weight: 700;
    font-size: 2rem;
  }
  
  .h6 {
    font-size: 1.5rem;
    font-weight: 400;
  }
  
  > * {
    margin: 0;
  }
`;

export const Img = styled(Image)`
  margin: 0 auto 20px auto;
  
  width: 165px;
  height: 165px;
  
  ${breakpoint.sm`
    margin-bottom: 0;
  `};

  [data-main-image],
  [data-placeholder-image] {
    border-radius: 100%;
  }
`;

